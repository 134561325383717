<template>
  <div>
    <!-- FILTROS DE PESQUISA -->
    <v-row dense class="pa-1">
      <v-col sm="3" cols="12" dense>
        <v-menu v-model="datepicker_datainicio_dialog" :close-on-content-click="false" transition="scale-transition"
          offset-y max-width="290px" min-width="auto">

          <template v-slot:activator="{ on }">
            <v-text-field outlined dense hide-details v-model="datainicio" label="Data início"
              prepend-inner-icon="mdi-calendar" readonly v-on="on"></v-text-field>
          </template>
          <v-date-picker locale="pt-br" v-model="date_start_field" no-title
            @input="datepicker_datainicio_dialog = false"></v-date-picker>
        </v-menu>
      </v-col>

      <v-col sm="3" cols="12" dense>
        <v-menu v-model="datepicker_datafim_dialog" :close-on-content-click="false" transition="scale-transition"
          offset-y max-width="290px" min-width="auto">

          <template v-slot:activator="{ on }">
            <v-text-field outlined dense v-model="datafim" hide-details label="Data fim" readonly
              prepend-inner-icon="mdi-calendar" v-on="on"></v-text-field>
          </template>
          <v-date-picker locale="pt-br" v-model="date_end_field" no-title @input="datepicker_datafim_dialog = false">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col sm="3" cols="12" dense>
        <v-btn :loading="loading" elevation="1" color="primary" @click="getAgendamentosPaciente">
          <v-icon small>mdi-table-search</v-icon>
          Pesquisar
        </v-btn>
      </v-col>
      <h2 class="mt-2 ">Total agendamentos:{{ agendamentos.length }}</h2>
    </v-row>
    <v-divider class="my-1 mx-1"></v-divider>
    <!-- LISTA DE AGENDAMENTOS -->
    <v-card outlined flat v-for="agenda, index in agendamentos" :key="index" class="pa-2 ma-1">
      <v-row align="center">
        <v-col dense>
          
          <v-row dense>
            <v-col sm="6" cols="12" dense>
              <b>Unidade: </b>
              <div class="pl-2">{{ agenda.ds_unidade_atendimento }} em {{ agenda.hr_agenda | formatCalendar }}</div>
            </v-col>
            <v-col sm="6" cols="12" dense>
              <b>Cód. AC:</b>
              <div class="pl-2">{{ agenda.cd_it_agenda_central }}</div>
            </v-col>
            <v-col sm="6" cols="12" dense v-if="agenda.cd_lateralidade ===null">
              <b>Médico: </b>
              <div class="pl-2">{{ agenda.nm_prestador }}</div>
            </v-col>
            <v-col sm="6" cols="12" dense>
              <b>Tipo Agenda: </b>
              <div class="pl-2">{{ agenda.tipo_agenda ===null  ?'Exame':agenda.tipo_agenda }}</div>
            </v-col>
            <v-col sm="6" cols="12" dense v-if="agenda.cd_lateralidade ===null">
              <b>Obs: {{ agenda.ds_observacao }} </b>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-0 pl-0" dense v-show="show_cancelar_remarcar">
          <v-row dense>
            <v-btn dark @click="deleteAgendamento(agenda)" color="#B21807" small>
              <v-icon left>mdi mdi-cancel </v-icon>
              CANCELAR
            </v-btn>
            <v-btn @click="remarcarAgendamento(agenda)" class="ml-1" dark color="#FF8C00" small>
              <v-icon left>mdi mdi-calendar-edit</v-icon>
              REMARCAR
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import AppEtlService from '@/service/AppEtlService'
import { mapState } from 'vuex'
const api = new AppEtlService();
var d = new Date();
var end = new Date()
end.setMonth(end.getMonth() + 1)
d.setDate(d.getDate() - 30)
export default {
  name: 'AgendamentosDoPaciente',
  props: {
    reload: {
      default: false,
      type: Boolean,
    },
    app:{
      type: String,
      default: null
    },
    paciente: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      datainicio: d.toLocaleDateString(),
      datafim: end.toLocaleDateString(),
      agendamentos: [],
      loading: false,
      date_end_field: null,
      date_start_field: null,
      datepicker_datainicio_dialog: false,
      datepicker_datafim_dialog: false,
    }
  },
  methods: {
    deleteAgendamento(agenda) {
      if (confirm('Deseja cancelar este agendamento?')) {
        api.put_etl('etl/query-put/?cod_query=del_agenda_paciente', JSON.stringify([agenda.cd_it_agenda_central]))
          .then(() => { this.getAgendamentosPaciente() })
          .catch(error => console.log(error))
      }
    },
    remarcarAgendamento(agenda) {
      if(agenda.cd_lateralidade===null){
        this.$emit('tabEmit', 2)
      this.$emit('selectedAgenda', agenda)
      this.$store.dispatch('agendaAtual', agenda)
      }else{
        this.$emit('tabEmit', 3)
      this.$emit('selectedExame', agenda)
      this.$store.dispatch('exameAtual', agenda)
      
      }
    },
    agendaFutura(val) {
      let agenda = new Date(val)
      let data_atual = new Date()
      return agenda > data_atual
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    dataFormatada() {
      let data = new Date();
      let dia = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate()
      let mes = data.getMonth() < 10 ? `0${data.getMonth()}` : data.getMonth()
      let ano = data.getFullYear()
      return { dia, mes, ano }
    },
    async getAgendamentosPaciente() {
      if (!this.datainicio || !this.datafim) {
        let data = this.dataFormatada()
        if (!this.datainicio) { this.datainicio = `${data.dia}/${data.mes}/${data.ano - 1}` }
        if (!this.datafim) { this.datafim = `${data.dia}/${data.mes}/${data.ano}` }
      }
      this.loading = true
      const response = await api
        .get_query('get_agenda_paciente', [this.paciente.cd_paciente, this.datainicio, this.datafim])
        .then(response => response)
        .catch(err => (err.response))
      this.agendamentos = response.data?.
        map(e => {
          e.show_btn_remarcar = this.agendaFutura(e.hr_agenda.split('T')[0])
          return e
        })
      this.loading = false
      this.$emit('reloaded')
    },
  },
  mounted() {
    this.getAgendamentosPaciente()
  },
  watch: {
    reload(val) {
      if (val) {
        this.getAgendamentosPaciente()
      }
    },
    date_start_field(val) {
      this.datainicio = this.formatDate(val)
    },
    date_end_field(val) {
      this.datafim = this.formatDate(val)
    },
  },
  computed: {
    ...mapState(['agendaAtual']),
    show_cancelar_remarcar(){
      return this.app=='agendar'?true:false
    }
  }
}
</script>